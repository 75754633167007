import React, { FC } from 'react';
import { YoutubeWrapper, Container, CloseIconWrapper, CloseIcon } from './YoutubePopupStyle';

interface YoutubePopupInterface {
    link: string;
    setIsActive: Function;
}

const YoutubePopup: FC<YoutubePopupInterface> = ({ link, setIsActive }) => {
    return (
        <Container>
            <YoutubeWrapper>
                <CloseIconWrapper>
                    <CloseIcon onClick={() => setIsActive(false)} />
                </CloseIconWrapper>
                <iframe
                    width="100%"
                    height="400"
                    src={link}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </YoutubeWrapper>
        </Container>
    );
};

export default YoutubePopup;
