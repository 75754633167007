import React, { useState, useRef } from 'react';
import Layout from '../../components/Layout/Layout';
import { graphql } from 'gatsby';
import {
    Title,
    SubTitle,
    HeaderProduct,
    NavigationButton,
    NavigationList,
    ProductComponent,
    ProductComponentWrapper,
    ProductWrapper,
    Select,
    SelectWrapper,
    SocialMedia,
    FacebookIcon,
    TwitterIcon,
    MailIcon,
} from './productStyle';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import ProductDetails from '../../components/Product/ProductDetails/ProductDetails';
import ProductDownloads from '../../components/Product/ProductDownloads/ProductDownloads';
import ProductOverview from '../../components/Product/ProductOverview/ProductOverview';
import ProductPlanningData from '../../components/Product/ProductPlanningData/ProductPlanningData';
import Header from '../../components/HeaderDynamicPages/Header';
import PrintWrapper from '../../components/PrintWrapper/PrintWrapper';
import ModalShare from '../../components/Product/ModalShare/ModalShare';
import Alternate from '../../components/Seo/Alternate';
import { dataToBreadcrumbsFromNav } from '../../utils/dataToBreadCrumbsFromNav';
import { DE, EN, EN_UK, ES, FR, ZH_HANS } from '../../constants/languageCode';

const Product = ({
    data: {
        drupal: { product: product },
    },
    location,
    pageContext: { languages: languages, noIndex: noIndex },
}) => {
    const { t } = useTranslation();
    const [activeMenu, setActiveMenu] = useState('overview');
    const [isMailModal, setIsMailModal] = useState(false);
    const { language } = useI18next();
    const siteUrl = location.href ? location.href : '';
    const planningDataLanguages = [DE, FR, ES, EN, EN_UK];
    const { category, item } = dataToBreadcrumbsFromNav(language, t('products').toLowerCase());
    const subCategory = { name: item, url: `/${language}/${t('products').toLowerCase()}/` };
    if (category) {
        category.name = t('product');
    }

    const overview = {
        field_product_long_text: product.field_product_long_text,
        field_product_short_text: product.field_product_short_text,
        field_product_advantages: product.field_product_advantages,
        field_product_image: product.field_product_image,
        title: product.title,
        field_product_variations: product.field_product_variations,
        field_product_accessories: product.field_product_accessories,
    };

    const details = {
        field_technical_details: product.field_technical_details,
        field_product_compressor_type: product.field_product_compressor_type,
        field_product_flow_capacity: product?.field_product_flow_capacity,
        field_product_delivery_quantity: product?.field_product_delivery_quantity,
        field_technical_data_image: product?.field_technical_data_image,
        field_show_technical_data_image: product?.field_show_technical_data_image,
    };

    const downloads = {
        field_proudct_downloads: product.field_proudct_downloads,
    };

    const isPlanningData = () => planningDataLanguages.includes(language);
    return (
        <Layout
            title={product?.metatag?.field_metatag_title}
            description={product?.metatag?.field_metatag_description}
            languageVersionsUrl={languages}
            noIndex={noIndex || product?.metatag?.field_metatag_no_index}
        >
            <Alternate languages={languages} />
            <PrintWrapper>
                {language === ZH_HANS ? (
                    <Header
                        category={{ name: '产品', url: '/zh-hans/products-overview/' }}
                        subCategory={{ name: 'Search Products', url: '/zh-hans/products/' }}
                        item={product.title}
                    />
                ) : (
                    <Header category={category} subCategory={subCategory} item={product.title} />
                )}
                <ProductWrapper>
                    <HeaderProduct>
                        {product.title && <Title>{product.title}</Title>}
                        {product.field_product_heading && <SubTitle>{product.field_product_heading}</SubTitle>}
                        <nav>
                            <NavigationList>
                                <li>
                                    <NavigationButton
                                        isActive={activeMenu === 'overview'}
                                        onClick={() => setActiveMenu('overview')}
                                    >
                                        {t('overview')}
                                    </NavigationButton>
                                </li>
                                <li>
                                    <NavigationButton
                                        isActive={activeMenu === 'technicalDetail'}
                                        onClick={() => setActiveMenu('technicalDetail')}
                                    >
                                        {t('technicalDetail')}
                                    </NavigationButton>
                                </li>
                                {product?.field_proudct_downloads && (
                                    <li>
                                        <NavigationButton
                                            isActive={activeMenu === 'downloads'}
                                            onClick={() => setActiveMenu('downloads')}
                                        >
                                            {t('downloads')}
                                        </NavigationButton>
                                    </li>
                                )}
                                {isPlanningData() && (
                                    <li>
                                        <NavigationButton
                                            isActive={activeMenu === 'planningData'}
                                            onClick={() => setActiveMenu('planningData')}
                                        >
                                            {t('planningData')}
                                        </NavigationButton>
                                    </li>
                                )}
                            </NavigationList>
                            <SelectWrapper>
                                <Select onChange={(e) => setActiveMenu(e.target.value)}>
                                    <option value="overview">{t('overview')}</option>
                                    <option value="technicalDetail">{t('technicalDetail')}</option>
                                    {product.field_proudct_downloads && (
                                        <option value="downloads">{t('downloads')}</option>
                                    )}
                                    {isPlanningData() && <option value="planningData">{t('planningData')}</option>}
                                </Select>
                            </SelectWrapper>
                        </nav>
                    </HeaderProduct>
                    <ProductComponent>
                        <ProductComponentWrapper>
                            <ProductOverview overview={overview} isActive={activeMenu === 'overview'} />
                            <ProductDetails
                                details={details}
                                technicalDataFootnote={product?.field_technical_data_footnote}
                                isActive={activeMenu === 'technicalDetail'}
                            />
                            <ProductDownloads downloads={downloads} isActive={activeMenu === 'downloads'} />
                            <ProductPlanningData planningData={details} isActive={activeMenu === 'planningData'} />
                        </ProductComponentWrapper>
                    </ProductComponent>
                    <SocialMedia>
                        <a href={`https://twitter.com/share?url=${siteUrl}&text=${product.title}`} target="_blank">
                            <TwitterIcon />
                        </a>
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${siteUrl}`} target="_blank">
                            <FacebookIcon />
                        </a>
                        <span onClick={() => setIsMailModal(true)}>
                            <MailIcon />
                        </span>
                    </SocialMedia>
                </ProductWrapper>
            </PrintWrapper>
            {isMailModal && <ModalShare title={product.title} setIsMailModal={setIsMailModal} />}
        </Layout>
    );
};

export default Product;

export const query = graphql`
    query product($id: Int!, $language: String!) {
        drupal {
            product(id: $id, language: $language) {
                id
                title
                field_product_compression_mode
                field_product_compressor_type
                field_product_cooling
                field_product_heading
                field_product_heat_recorvery
                field_product_id
                field_product_long_text
                field_product_more_properties
                field_product_pressure_range
                field_product_short_text
                field_product_sound_absorption
                field_technical_data_footnote
                field_product_flow_capacity
                field_product_delivery_quantity
                field_product_image {
                    field_media_image
                    field_type
                }
                field_product_advantages {
                    name
                    id
                    field_image {
                        field_media_image
                        field_type
                    }
                }
                field_technical_details {
                    field_product_dimension_b_h_t_mm
                    field_product_fad_cfm_max
                    field_product_fad_cfm_min
                    field_product_fad_m3_max
                    field_product_fad_m3_min
                    field_product_frequecy
                    field_product_motor_power_kw
                    field_product_motor_power_kw_max
                    field_product_motor_power_ps
                    field_product_motor_power_ps_max
                    field_product_pressure_bar
                    field_product_pressure_bar_max
                    field_product_pressure_psig
                    field_product_pressure_psig_max
                    field_product_type_text
                    field_product_weight
                    field_sound_pressure_level
                    field_product_type {
                        field_image {
                            field_type
                            field_media_image
                        }
                        id
                        name
                    }
                }
                field_show_technical_data_image
                field_technical_data_image {
                    field_media_image
                    field_type
                    alt
                    title
                }
                field_proudct_downloads {
                    field_media_document
                    details {
                        weight
                        filemime
                    }
                    description
                }
                field_product_variations {
                    id
                    field_product_image {
                        field_media_image
                    }
                    url
                    title
                }
                field_product_accessories {
                    id
                    field_product_image {
                        field_media_image
                        field_type
                    }
                    url
                    title
                }
                field_technical_data_footnote
                metatag {
                    field_metatag_description
                    field_metatag_title
                    field_metatag_no_index
                }
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
