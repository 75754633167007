import styled from 'styled-components';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { deviceMax } from '../../../styles/MediaQuery';

interface ProductImageType {
    readonly isActive: boolean;
    readonly position: boolean;
}

export const ProductImage = styled.img<ProductImageType>`
    width: 100%;
    max-height: 100%;
    transition: 0.3s;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: ${(props) => {
        if (props.isActive) {
            return '0';
        } else if (props.position) {
            return '-1000px';
        } else {
            return '1000px';
        }
    }};
`;

export const TemplateImg = styled.img`
    width: 80%;
`;

export const Slider = styled.div`
    max-width: 468px;
    height: 460px;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 0.5em;

    @media ${deviceMax.laptop} {
        margin: unset;
    }

    @media ${deviceMax.tablet} {
        width: 80%;
        min-width: 300px;
        height: 300px;
    }
`;

export const BackIcon = styled(IoIosArrowBack)`
    font-size: 1.5em;
    fill: white;
`;

export const ForwardIcon = styled(IoIosArrowForward)`
    font-size: 1.5em;
    fill: white;
`;

export const IconWrapper = styled.div`
    width: 48px;
    height: 48px;
    position: absolute;
    background-color: black;
    opacity: 0.2;
    top: 40%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
    z-index: 2;

    &:hover {
        opacity: 0.3;
    }
`;

export const BackIconWrapper = styled(IconWrapper)`
    left: 0;
`;

export const ForwardIconWrapper = styled(IconWrapper)`
    right: 0;
`;
