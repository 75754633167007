import React, { FC } from 'react';
import { YoutubeWrapper, Youtube, YoutubeText, YoutubePlay, YoutubePlayIcon } from '../HeaderStyle';

interface VideoButtonInterface {
    setIsYoutubeActive: Function;
}

const VideoButton: FC<VideoButtonInterface> = ({ setIsYoutubeActive }) => (
    <YoutubeWrapper>
        <Youtube onClick={() => setIsYoutubeActive(true)}>
            <YoutubePlay>
                <YoutubePlayIcon />
            </YoutubePlay>
            <YoutubeText>Video</YoutubeText>
        </Youtube>
    </YoutubeWrapper>
);

export default VideoButton;
