import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
    Container,
    ListWrapper,
    ListWrapperMobile,
    ProductList,
    ProductListItem,
    NumberWrapper,
    Title,
    TitleText,
    TypesList,
    CheckBox,
    TypeListItem,
    Types,
    TypesWrapper,
    PlanningDataLink,
    ProductListOneLine,
    ProductListOneLineItem,
} from './ProductPlanningDataStyle';
import defaultTypes from '../../../JSON/ProductPlanningDataBIMTypes/defaultTypes.json';
import { useI18next } from 'gatsby-plugin-react-i18next';

const ProductPlanningData = ({ planningData: { field_technical_details }, isActive }) => {
    const { t } = useTranslation();
    const [checkedTypes, setCheckedTypes] = useState([]);
    const [checkedProduct, setCheckedProduct] = useState('');
    const [sendingLink, setSendingLink] = useState('');
    const { language } = useI18next();
    const divideProductListToThreeColumns = 3;
    const divideProducListToTwoColumns = 2;

    useEffect(() => {
        checkedProduct.length === 0 && setCheckedTypes([]);
    }, [checkedProduct]);

    useEffect(() => {
        let myLink = '';
        checkedTypes.forEach((type) => {
            myLink = myLink + `&docType=${type}`;
        });
        const bogeSupportLink =
            process.env.BOGE_SUPPORT_LINK.slice(-1) === '/'
                ? process.env.BOGE_SUPPORT_LINK
                : `${process.env.BOGE_SUPPORT_LINK}/`;
        if (language === 'en-uk') {
            setSendingLink(`${bogeSupportLink}uk/bim/product/documents?lang=en&productType=${checkedProduct}${myLink}`);
        } else {
            setSendingLink(
                `${bogeSupportLink}${language}/bim/product/documents?lang=${language}&productType=${checkedProduct}${myLink}`
            );
        }
    }, [checkedTypes]);

    const filter50HzDetails = () => field_technical_details?.filter((detail) => !detail?.field_product_frequecy);

    const removeDuplicatedProducts = (products) =>
        Array.from(
            new Set(
                products
                    ?.filter((product) => product.field_product_type?.length > 0)
                    ?.map((product) => product.field_product_type[0]?.name)
            )
        )?.map((name) => {
            return products
                ?.filter((product) => product.field_product_type?.length > 0)
                ?.find((product) => product.field_product_type[0]?.name === name);
        });

    const changeProduct = (detail: any): void => {
        if (detail.field_product_type?.length > 0) {
            setCheckedProduct((checkedProduct) =>
                checkedProduct === detail.field_product_type[0].name ? '' : detail.field_product_type[0].name
            );
            setCheckedTypes([]);
        }
    };

    const displayProducts = (): ReactNode => {
        let products = removeDuplicatedProducts(filter50HzDetails());
        if (products?.length > divideProductListToThreeColumns) {
            return (
                <>
                    <ListWrapper>
                        <ProductList>
                            {products
                                .slice(0, Math.ceil(products.length / divideProductListToThreeColumns))
                                ?.filter((detail) => detail?.field_product_type?.length > 0)
                                ?.map((detail, index) => displayProduct(detail, index))}
                        </ProductList>
                        <ProductList>
                            {products
                                .slice(
                                    Math.ceil(products.length / divideProductListToThreeColumns),
                                    Math.ceil((products.length / 3) * 2)
                                )
                                ?.filter((detail) => detail?.field_product_type?.length > 0)
                                ?.map((detail, index) => displayProduct(detail, index))}
                        </ProductList>
                        <ProductList>
                            {products
                                .slice(Math.ceil((products.length / divideProductListToThreeColumns) * 2))
                                ?.filter((detail) => detail?.field_product_type?.length > 0)
                                ?.map((detail, index) => displayProduct(detail, index))}
                        </ProductList>
                    </ListWrapper>
                    <ListWrapperMobile>
                        <ProductList>
                            {products
                                .slice(0, Math.ceil(products.length / divideProducListToTwoColumns))
                                ?.filter((detail) => detail?.field_product_type?.length > 0)
                                ?.map((detail, index) => displayProduct(detail, index))}
                        </ProductList>
                        <ProductList>
                            {products
                                .slice(Math.ceil(products.length / divideProducListToTwoColumns))
                                ?.filter((detail) => detail?.field_product_type?.length > 0)
                                ?.map((detail, index) => displayProduct(detail, index))}
                        </ProductList>
                    </ListWrapperMobile>
                </>
            );
        } else {
            return (
                <ProductListOneLine>
                    {products
                        ?.filter((detail) => detail?.field_product_type?.length > 0)
                        ?.map((detail, index) => (
                            <ProductListOneLineItem key={index} onClick={() => changeProduct(detail)}>
                                {detail.field_product_type[0].name}
                            </ProductListOneLineItem>
                        ))}
                </ProductListOneLine>
            );
        }
    };

    const displayProduct = (detail, index: number): ReactNode => (
        <ProductListItem key={index} onClick={() => changeProduct(detail)}>
            {detail.field_product_type[0].name}
        </ProductListItem>
    );

    const displayTypes = (): ReactNode => {
        if (defaultTypes.length > 2) {
            return (
                <>
                    <TypesList>
                        {defaultTypes.slice(0, Math.ceil(defaultTypes.length / 2)).map((type) => displayType(type))}
                    </TypesList>
                    <TypesList>
                        {defaultTypes.slice(Math.ceil(defaultTypes.length / 2)).map((type) => displayType(type))}
                    </TypesList>
                </>
            );
        } else {
            return <TypesList>{defaultTypes.map((type) => displayType(type))}</TypesList>;
        }
    };

    const displayType = (type) => {
        const [typeStatus, setTypeStatus] = useState('');
        const checkType = () => {
            setTypeStatus((typeStatus) => (typeStatus === 'active' ? 'unActive' : 'active'));
        };

        useEffect(() => {
            setTypeStatus('');
        }, [checkedProduct]);

        useEffect(() => {
            if (typeStatus === 'active') {
                setCheckedTypes([...checkedTypes, type?.code]);
            } else if (typeStatus === 'unActive') {
                setCheckedTypes([
                    ...checkedTypes
                        .slice(
                            0,
                            checkedTypes.findIndex((types) => types === type?.code)
                        )
                        .concat(...checkedTypes.slice(checkedTypes.findIndex((types) => types === type?.code) + 1)),
                ]);
            }
        }, [typeStatus]);
        return (
            <TypeListItem
                key={type?.name}
                onMouseEnter={() => setTypeStatus((typeStatus) => (typeStatus === 'active' ? 'active' : 'hover'))}
                onMouseLeave={() => setTypeStatus((typeStatus) => (typeStatus === 'hover' ? '' : typeStatus))}
                onClick={checkType}
            >
                <CheckBox status={typeStatus} /> {t(type?.name)}
            </TypeListItem>
        );
    };

    return (
        <Container isActive={isActive}>
            <Title>
                <NumberWrapper>1</NumberWrapper>
                <TitleText>{t('selectTheDesiredType')}</TitleText>
            </Title>
            {displayProducts()}
            <TypesWrapper isActive={!!checkedProduct}>
                <Title>
                    <NumberWrapper>2</NumberWrapper>
                    <TitleText>
                        {checkedProduct}: {t('chooseTheDesiredDocuments')}
                    </TitleText>
                </Title>
                <Types>{displayTypes()}</Types>
            </TypesWrapper>
            {checkedTypes.length > 0 && (
                <PlanningDataLink href={sendingLink} target="_blank">
                    {t('requestDocuments')}
                </PlanningDataLink>
            )}
        </Container>
    );
};

export default ProductPlanningData;
