import React, { ReactNode, useEffect, useState } from 'react';
import {
    Container,
    AdvantagesListWrapper,
    AdvantagesList,
    AdvantagesItem,
    ProductDescription,
    SliderWrapper,
    Description,
    ProductPhoto,
} from './ProductOverviewStyle';
import { ProductImage } from '../../Sliders/SinglePhotoSlider/SinglePhotoSliderStyle';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import MultiPhotoSlider from '../../Sliders/MultiPhotoSlider/MultiPhotoSlider';
import { urlForImage } from '../../../utils/urlForImage';
import useBrowserWidth from '../../../hooks/useBrowserWidth';
import { MOBILE_SCREEN_SIZE, TABLET_SCREEN_SIZE } from '../../../styles/MediaQuery';

const ProductOverview = ({
    overview: {
        field_product_long_text,
        field_product_short_text,
        field_product_advantages,
        field_product_image,
        title,
        field_product_variations,
        field_product_accessories,
    },
    isActive,
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    enum ImagesToDisplayDefault {
        Accessories = 3,
        Variations = 2,
    }

    const [accessoriesImagesToDisplay, setAccessoriesImagesToDisplay] = useState(ImagesToDisplayDefault.Accessories);
    const [variationsImagesToDisplay, setVariationsImagesToDisplay] = useState(ImagesToDisplayDefault.Variations);
    const currentBrowserWidth = useBrowserWidth();

    useEffect(() => {
        if (currentBrowserWidth <= TABLET_SCREEN_SIZE && currentBrowserWidth > MOBILE_SCREEN_SIZE) {
            setAccessoriesImagesToDisplay(2);
            setVariationsImagesToDisplay(2);
        } else if (currentBrowserWidth <= MOBILE_SCREEN_SIZE) {
            setAccessoriesImagesToDisplay(1);
            setVariationsImagesToDisplay(1);
        } else {
            setAccessoriesImagesToDisplay(ImagesToDisplayDefault.Accessories);
            setVariationsImagesToDisplay(ImagesToDisplayDefault.Variations);
        }
    }, [currentBrowserWidth]);

    const displayAdvantages = (): ReactNode => {
        if (field_product_advantages?.length > 2) {
            return (
                <AdvantagesListWrapper>
                    <AdvantagesList>
                        {field_product_advantages
                            .slice(0, Math.ceil(field_product_advantages.length / 2))
                            .map((advantage) => displayAdventage(advantage))}
                    </AdvantagesList>
                    <AdvantagesList>
                        {field_product_advantages
                            .slice(Math.ceil(field_product_advantages.length / 2))
                            .map((advantage) => displayAdventage(advantage))}
                    </AdvantagesList>
                </AdvantagesListWrapper>
            );
        } else if (field_product_advantages) {
            return (
                <AdvantagesList>
                    {field_product_advantages.map((advantage) => displayAdventage(advantage))}
                </AdvantagesList>
            );
        } else {
            return null;
        }
    };

    const displayAdventage = (advantage: any): ReactNode => (
        <AdvantagesItem key={advantage.id}>{advantage.name}</AdvantagesItem>
    );

    return (
        <Container isActive={isActive}>
            <SliderWrapper>
                <ProductPhoto>
                    {field_product_image &&
                        field_product_image.length > 0 &&
                        field_product_image[0].field_media_image && (
                            <ProductImage
                                position={false}
                                isActive={true}
                                src={urlForImage(field_product_image[0].field_media_image)}
                                alt={title}
                            />
                        )}
                </ProductPhoto>
            </SliderWrapper>
            <Description>
                {field_product_long_text && (
                    <ProductDescription dangerouslySetInnerHTML={{ __html: field_product_long_text }} />
                )}
                {field_product_short_text && (
                    <div>
                        <h3>{t('product')}</h3>
                        <ProductDescription dangerouslySetInnerHTML={{ __html: field_product_short_text }} />
                    </div>
                )}
                {field_product_advantages && (
                    <div>
                        <h3>{t('advantages')}</h3>
                        {displayAdvantages()}
                    </div>
                )}
            </Description>
            {field_product_accessories && (
                <div>
                    <h3>{t('compressedAirAccesoriesForThisProduct')}</h3>
                    <MultiPhotoSlider
                        language={language}
                        images={field_product_accessories}
                        numberOfImagesToDisplay={accessoriesImagesToDisplay}
                    />
                </div>
            )}
            {field_product_variations && (
                <div>
                    <h3>{t('otherConstructionVariants')}</h3>
                    <MultiPhotoSlider
                        language={language}
                        images={field_product_variations}
                        numberOfImagesToDisplay={variationsImagesToDisplay}
                    />
                </div>
            )}
        </Container>
    );
};

export default ProductOverview;
