import React, { FC, ReactNode, useEffect, useState } from 'react';
import {
    Slider,
    ProductImage,
    ProductText,
    AccessoriesProductWrapper,
    BackIconWrapper,
    ExternalSliderLink,
    ForwardIconWrapper,
    ForwardIcon,
    BackIcon,
    SliderLink,
} from './MultiPhotoSliderStyle';
import placeHolder from '../../../static/images/placeholder.jpg';
import { urlForImage } from '../../../utils/urlForImage';
import { linkHelper } from '../../../utils/linkHelper';
interface MultiPhotoSlider {
    productCombination?: boolean;
    bigSlider?: boolean;
    images?: any;
    numberOfImagesToDisplay?: number;
    language?: string;
    useAutoWidth?: boolean;
}

const MultiPhotoSlider: FC<MultiPhotoSlider> = ({
    productCombination,
    bigSlider,
    images,
    numberOfImagesToDisplay,
    language,
    useAutoWidth = false,
}) => {
    const [lastActivePhoto, setLastActivePhoto] = useState(numberOfImagesToDisplay - 1);
    const [numberOfPhotos, setNumberOfPhotos] = useState(images?.length);
    const [displayArrows, setDisplayArrows] = useState(images?.length > numberOfImagesToDisplay);

    useEffect(() => {
        setLastActivePhoto(numberOfImagesToDisplay - 1);
        setDisplayArrows(images?.length > numberOfImagesToDisplay);
    }, [numberOfImagesToDisplay]);

    const nextImage = (): void => {
        lastActivePhoto < numberOfPhotos - 1
            ? setLastActivePhoto((lastActivePhoto) => ++lastActivePhoto)
            : setLastActivePhoto(numberOfImagesToDisplay - 1);
    };

    const previousImage = (): void => {
        lastActivePhoto === numberOfImagesToDisplay - 1
            ? setLastActivePhoto(numberOfPhotos - 1)
            : setLastActivePhoto((lastActivePhoto) => --lastActivePhoto);
    };

    const displaySlider = (): ReactNode =>
        images?.map((product: any, index: number) => {
            const productImage =
                product.field_product_image?.length > 0
                    ? urlForImage(product.field_product_image[0].field_media_image)
                    : urlForImage(product.field_media_image);
            return (
                <SliderLink to={linkHelper(product.url ?? '#', language)} key={index}>
                    <AccessoriesProductWrapper
                        position={index}
                        lastActivePhoto={lastActivePhoto}
                        hiddenPosition={index < lastActivePhoto - (numberOfImagesToDisplay - 1)}
                        numberOfImagesToDisplay={numberOfImagesToDisplay}
                        numberOfPhotos={numberOfPhotos}
                        useAutoWidth={useAutoWidth}
                    >
                        <ProductImage src={productImage ? productImage : placeHolder} alt={product.title} />
                        {product?.title && <ProductText>{product.title}</ProductText>}
                    </AccessoriesProductWrapper>
                </SliderLink>
            );
        });

    const displayBigSlider = (): ReactNode =>
        images?.map((product: any, index: number) => (
            <ExternalSliderLink
                href={linkHelper(product?.field_link ? product?.field_link.uri : '#', language)}
                key={product?.field_link + index}
            >
                <AccessoriesProductWrapper
                    bigSlider={bigSlider}
                    position={index}
                    lastActivePhoto={lastActivePhoto}
                    hiddenPosition={index < lastActivePhoto - (numberOfImagesToDisplay - 1)}
                    numberOfImagesToDisplay={numberOfImagesToDisplay}
                    numberOfPhotos={numberOfPhotos}
                    useAutoWidth={useAutoWidth}
                >
                    {product?.field_title && <ProductText bigSlider={bigSlider}>{product.field_title}</ProductText>}
                    <ProductImage
                        bigSlider={bigSlider}
                        src={
                            product?.field_image?.length > 0
                                ? urlForImage(product.field_image[0]?.field_media_image)
                                : placeHolder
                        }
                        alt={product?.field_image?.length > 0 ? product.field_image[0]?.alt : 'product image'}
                    />
                </AccessoriesProductWrapper>
            </ExternalSliderLink>
        ));

    const displayPhotoCombinationSlider = (): ReactNode =>
        images
            ?.filter((product) => product?.hasOwnProperty('field_media_image'))
            ?.map((product: any, index: number) => (
                <AccessoriesProductWrapper
                    key={product.field_media_image + index}
                    bigSlider={bigSlider}
                    position={index}
                    lastActivePhoto={lastActivePhoto}
                    hiddenPosition={index < lastActivePhoto - (numberOfImagesToDisplay - 1)}
                    numberOfImagesToDisplay={numberOfImagesToDisplay}
                    numberOfPhotos={numberOfPhotos}
                    useAutoWidth={useAutoWidth}
                >
                    <ProductImage
                        bigSlider={bigSlider}
                        src={urlForImage(product.field_media_image)}
                        alt={'product combination image'}
                    />
                </AccessoriesProductWrapper>
            ));

    const displayCorrectSlider = (): ReactNode => {
        if (bigSlider && productCombination) {
            return displayPhotoCombinationSlider();
        } else if (bigSlider && !productCombination) {
            return displayBigSlider();
        } else {
            return displaySlider();
        }
    };

    return (
        <Slider bigSlider={bigSlider} numberOfImagesToDisplay={numberOfImagesToDisplay} useAutoWidth={useAutoWidth}>
            {displayArrows && (
                <BackIconWrapper bigSlider={bigSlider} onClick={previousImage}>
                    <BackIcon bigSlider={bigSlider} />
                </BackIconWrapper>
            )}
            {displayCorrectSlider()}
            {displayArrows && (
                <ForwardIconWrapper bigSlider={bigSlider} onClick={nextImage}>
                    <ForwardIcon bigSlider={bigSlider} />
                </ForwardIconWrapper>
            )}
        </Slider>
    );
};

export default MultiPhotoSlider;
