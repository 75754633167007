import React, { useState } from 'react';
import {
    Container,
    NavigationButton,
    Details,
    Table,
    TableHeaderItem,
    TableHeaderItemUnits,
    TableSubHeaderItem,
    TableRow,
    TableItem,
    TechnicalDataFootnoteText,
    Image,
    ImageWrapper,
} from './ProductDetailsStyle';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { EN_US, ES_US } from '../../../constants/languageCode';
import { languagesWithDotInNumber } from '../../../constants/languagesWithDotInNumber';
import { PISTON_COMPRESSOR } from '../../../constants/commonConstants';
import { urlForImage } from '../../../utils/urlForImage';

const ProductDetails = ({
    details: {
        field_technical_details,
        field_product_compressor_type,
        field_product_flow_capacity,
        field_product_delivery_quantity,
        field_technical_data_image,
        field_show_technical_data_image,
    },
    technicalDataFootnote,
    isActive,
}) => {
    const { t } = useTranslation();
    const { language } = useI18next();
    const checkFrequency = () => field_technical_details?.some((detail) => detail?.field_product_frequecy);
    const checkMotorPower = (): boolean =>
        field_technical_details?.some(
            (detail) => detail?.field_product_motor_power_kw || detail?.field_product_motor_power_ps
        );
    const checkDimension = (): boolean =>
        field_technical_details?.some((detail) => detail?.field_product_dimension_b_h_t_mm);
    const checkWeight = (): boolean => field_technical_details?.some((detail) => detail?.field_product_weight);
    const checkDeliveryVolume = (): boolean =>
        field_technical_details?.some(
            (detail) => detail?.field_product_fad_m3_min || detail?.field_product_fad_cfm_min
        );
    const checkPressure = (): boolean =>
        field_technical_details?.some(
            (detail) => detail?.field_product_pressure_bar || detail?.field_product_pressure_psig
        );

    const [isFrequency, setIsFrequency] = useState(checkFrequency());
    const [is50Hz, setIs50Hz] = useState(![EN_US, ES_US].includes(language));
    const isMotorPower = checkMotorPower();
    const isDimension = checkDimension();
    const isProductWeight = checkWeight();
    const isDeliveryVolume = checkDeliveryVolume();
    const isPressure = checkPressure();
    const hintStar = technicalDataFootnote?.includes('*');
    const fieldTechnicalDataImage = {
        alt: field_technical_data_image?.[0]?.alt ?? '',
        title: field_technical_data_image?.[0]?.title ?? '',
        src: field_technical_data_image?.[0]?.field_media_image
            ? urlForImage(field_technical_data_image?.[0]?.field_media_image)
            : '',
    };

    const getNumberInCorrectFormat = (number: string | number): string | number =>
        number ? (languagesWithDotInNumber.includes(language) ? number : number.toString().replace('.', ',')) : '';

    const display50HzDetails = () => {
        return field_technical_details
            ?.filter((detail) => !detail?.field_product_frequecy)
            ?.map((detail, index) => displayTableDetailsRow(detail, index));
    };

    const display60HzDetails = () => {
        return field_technical_details
            ?.filter((detail) => detail?.field_product_frequecy)
            ?.map((detail, index) => displayTableDetailsRow(detail, index));
    };

    const displayTableDetailsRow = (detail, index) => (
        <TableRow key={index}>
            {detail?.field_product_type?.length > 0 && (
                <TableItem addRightBorder={true}>{detail?.field_product_type[0]?.name}</TableItem>
            )}
            {isPressure && ![EN_US, ES_US].includes(language) && (
                <TableItem addRightBorder={false}>
                    {getNumberInCorrectFormat(detail?.field_product_pressure_bar)}
                </TableItem>
            )}
            {isPressure && (
                <TableItem addRightBorder={true}>
                    {getNumberInCorrectFormat(detail?.field_product_pressure_psig)}
                </TableItem>
            )}
            {isDeliveryVolume && (
                <>
                    <TableItem addRightBorder={false}>
                        {detail?.field_product_fad_m3_min &&
                            getNumberInCorrectFormat(detail.field_product_fad_m3_min.toFixed(3))}
                    </TableItem>
                    <TableItem addRightBorder={!checkMinMaxField()}>
                        {detail?.field_product_fad_cfm_min &&
                            getNumberInCorrectFormat(Math.round(detail.field_product_fad_cfm_min * 10) / 10 + '')}
                    </TableItem>
                </>
            )}
            {checkMinMaxField() && (
                <>
                    <TableItem addRightBorder={false}>
                        {getNumberInCorrectFormat(detail?.field_product_fad_m3_max?.toFixed(3))}
                    </TableItem>
                    <TableItem addRightBorder={true}>
                        {getNumberInCorrectFormat(Math.round(detail?.field_product_fad_cfm_max * 10) / 10 + '')}
                    </TableItem>
                </>
            )}
            {isMotorPower && ![EN_US, ES_US].includes(language) && (
                <TableItem addRightBorder={false}>
                    {getNumberInCorrectFormat(detail?.field_product_motor_power_kw)}
                </TableItem>
            )}
            {isMotorPower && (
                <TableItem addRightBorder={true}>
                    {getNumberInCorrectFormat(detail?.field_product_motor_power_ps)}
                </TableItem>
            )}
            {isDimension && (
                <TableItem addRightBorder={true}>
                    {getNumberInCorrectFormat(detail?.field_product_dimension_b_h_t_mm)}
                </TableItem>
            )}
            {isProductWeight && (
                <TableItem addRightBorder={true}>{getNumberInCorrectFormat(detail?.field_product_weight)}</TableItem>
            )}
        </TableRow>
    );

    const checkMinMaxField = () => field_technical_details?.some((detail) => detail?.field_product_fad_m3_max);

    const renderTechnicalDataTable = () => (
        <>
            <NavigationButton onClick={() => setIs50Hz(![EN_US, ES_US].includes(language))}>
                {![EN_US, ES_US].includes(language) ? '50 Hz' : '60 Hz'}
            </NavigationButton>
            {isFrequency && (
                <NavigationButton onClick={() => setIs50Hz([EN_US, ES_US].includes(language))}>
                    {![EN_US, ES_US].includes(language) ? '60 Hz' : '50 Hz'}
                </NavigationButton>
            )}
            <Details>
                <Table>
                    <thead>
                        <tr>
                            <TableHeaderItem>BOGE</TableHeaderItem>
                            {isPressure && (
                                <TableHeaderItem colSpan={[EN_US, ES_US].includes(language) ? 1 : 2}>
                                    {is50Hz ? t('maxPressure') : t('pressure')}
                                </TableHeaderItem>
                            )}
                            {isDeliveryVolume && (
                                <TableHeaderItemUnits colSpan={checkMinMaxField() ? 4 : 2}>
                                    {field_product_delivery_quantity
                                        ? `${t('effectiveDeliveryQuantity')}`
                                        : `${
                                              field_product_flow_capacity
                                                  ? t('flowCapacity')
                                                  : t('effectiveDeliveryVolume')
                                          }${
                                              hintStar || field_product_compressor_type === PISTON_COMPRESSOR ? '*' : ''
                                          }`}
                                    <br />
                                    {!field_product_delivery_quantity && (is50Hz ? '50 Hz' : '60 Hz')}
                                </TableHeaderItemUnits>
                            )}
                            {isMotorPower && (
                                <TableHeaderItem colSpan={[EN_US, ES_US].includes(language) ? 1 : 2}>
                                    {t('motorPower')}
                                </TableHeaderItem>
                            )}
                            {isDimension && (
                                <TableHeaderItemUnits>
                                    {t('dimensions')}
                                    <br />
                                    {t('dimensionsUnits')}
                                </TableHeaderItemUnits>
                            )}
                            {isProductWeight && <TableHeaderItem>{t('weight')}</TableHeaderItem>}
                        </tr>
                        <tr>
                            <TableSubHeaderItem addRightBorder={true}>{t('type')}</TableSubHeaderItem>
                            {isPressure && ![EN_US, ES_US].includes(language) && (
                                <TableSubHeaderItem addRightBorder={false}>bar</TableSubHeaderItem>
                            )}
                            {isPressure && <TableSubHeaderItem addRightBorder={true}>psig</TableSubHeaderItem>}
                            {isDeliveryVolume && (
                                <>
                                    <TableSubHeaderItem addRightBorder={false}>
                                        m<sup>3</sup>/min
                                    </TableSubHeaderItem>
                                    <TableSubHeaderItem addRightBorder={!checkMinMaxField()}>cfm</TableSubHeaderItem>
                                </>
                            )}
                            {checkMinMaxField() && (
                                <>
                                    <TableSubHeaderItem addRightBorder={false}>
                                        m<sup>3</sup>/min(max)
                                    </TableSubHeaderItem>
                                    <TableSubHeaderItem addRightBorder={true}>cfm(max)</TableSubHeaderItem>
                                </>
                            )}
                            {isMotorPower && ![EN_US, ES_US].includes(language) && (
                                <TableSubHeaderItem addRightBorder={false}>kW</TableSubHeaderItem>
                            )}
                            {isMotorPower && <TableSubHeaderItem addRightBorder={true}>{t('hp')}</TableSubHeaderItem>}
                            {isDimension && <TableSubHeaderItem addRightBorder={true}>{t('mm')}</TableSubHeaderItem>}
                            {isProductWeight && (
                                <TableSubHeaderItem addRightBorder={true}>{t('kg')}</TableSubHeaderItem>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {is50Hz
                            ? display50HzDetails()
                            : [EN_US, ES_US].includes(language)
                            ? display50HzDetails()
                            : display60HzDetails()}
                    </tbody>
                </Table>
            </Details>
            {technicalDataFootnote !== null ? (
                <TechnicalDataFootnoteText>{technicalDataFootnote}</TechnicalDataFootnoteText>
            ) : (
                field_product_compressor_type === PISTON_COMPRESSOR && (
                    <TechnicalDataFootnoteText>* {t('effectiveFreeAirDelivery')}</TechnicalDataFootnoteText>
                )
            )}
        </>
    );

    const renderTechnicalDataImage = () => (
        <ImageWrapper>
            <Image
                {...(fieldTechnicalDataImage.alt && { alt: fieldTechnicalDataImage.alt })}
                {...(fieldTechnicalDataImage.title && { title: fieldTechnicalDataImage.title })}
                src={fieldTechnicalDataImage.src}
            />
        </ImageWrapper>
    );

    const renderTechnicalData = () =>
        field_show_technical_data_image ? renderTechnicalDataImage() : renderTechnicalDataTable();

    return <Container isActive={isActive}>{renderTechnicalData()}</Container>;
};

export default ProductDetails;
