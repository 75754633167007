import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { blackSliderPhotoBorder, greyLight } from '../../../styles/Variables';
import { deviceMax, MOBILE_SCREEN_SIZE } from '../../../styles/MediaQuery';
import { StyledArrowBack, StyledArrowForward } from './MultiPhotoSliderArrows';

interface SliderType {
    readonly numberOfImagesToDisplay?: number;
    readonly bigSlider?: boolean;
    readonly useAutoWidth?: boolean;
}

const productMargin = 12;
const productSize = 127;
const sliderItemWidth = productSize + productMargin * 2;

export const ProductImage = styled.img<SliderType>`
    width: 100%;
    max-height: 100%;
    height: ${productSize}px;
    width: ${productSize}px;
    transition: 0.3s;
    border: ${(props) => (props.bigSlider ? 'none' : `1px solid ${blackSliderPhotoBorder}`)};
`;

export const Slider = styled.div<SliderType>`
    background: ${(props) => (props.bigSlider ? `${greyLight}` : 'none')};
    width: ${(props) => (props.bigSlider ? '100%' : `${props.numberOfImagesToDisplay * sliderItemWidth}px`)};
    height: 240px;
    overflow: hidden;
    position: relative;

    @media ${deviceMax.tablet} {
        width: ${(props) =>
            props.bigSlider
                ? '100%'
                : props.numberOfImagesToDisplay > 2
                ? `${2 * sliderItemWidth}px`
                : `${props.numberOfImagesToDisplay * sliderItemWidth}px`};
    }

    ${({ useAutoWidth }) =>
        !useAutoWidth &&
        css`
            @media (max-width: ${MOBILE_SCREEN_SIZE}px) {
                width: ${sliderItemWidth}px;
            }
        `}
`;

export const SliderLink = styled(Link)`
    text-decoration: none;
`;

export const ExternalSliderLink = styled.a`
    text-decoration: none;
`;

interface AccessoriesProductWrapperType {
    readonly position: number;
    readonly lastActivePhoto: number;
    readonly hiddenPosition: boolean;
    readonly numberOfImagesToDisplay: number;
    readonly numberOfPhotos: number;
    readonly bigSlider?: boolean;
    readonly useAutoWidth?: boolean;
}

export const AccessoriesProductWrapper = styled.div<AccessoriesProductWrapperType>`
    width: ${(props) => (props.bigSlider ? '100%' : `${productSize}px`)};
    min-height: 100%;
    margin: ${(props) => (props.useAutoWidth ? `0` : `0 ${productMargin}px`)};
    position: absolute;
    top: ${(props) => (props.bigSlider ? '25%' : '0')};
    transition: 0.3s;
    text-align: center;
    text-decoration: none;
    left: ${(props) => {
        for (let i = 0; i < props.numberOfPhotos; i++) {
            if (i < props.numberOfImagesToDisplay) {
                if (props.lastActivePhoto - props.position === i) {
                    return `${(100 / props.numberOfImagesToDisplay) * (props.numberOfImagesToDisplay - 1 - i)}%`;
                }
            } else if (props.hiddenPosition) {
                return `-100%`;
            } else {
                return '105%';
            }
        }
    }};

    @media ${deviceMax.tablet} {
        left: ${(props) => {
            if (props.numberOfImagesToDisplay > 2) {
                for (let i = 0; i < props.numberOfPhotos; i++) {
                    if (i < 2) {
                        if (props.lastActivePhoto - props.position === i) {
                            return `${(100 / 2) * (2 - 1 - i)}%`;
                        }
                    } else if (props.hiddenPosition) {
                        return `-100%`;
                    } else {
                        return '105%';
                    }
                }
            } else {
                for (let i = 0; i < props.numberOfPhotos; i++) {
                    if (i < props.numberOfImagesToDisplay) {
                        if (props.lastActivePhoto - props.position === i) {
                            return `${
                                (100 / props.numberOfImagesToDisplay) * (props.numberOfImagesToDisplay - 1 - i)
                            }%`;
                        }
                    } else if (props.hiddenPosition) {
                        return `-100%`;
                    } else {
                        return '105%';
                    }
                }
            }
        }};
    }

    @media (max-width: ${MOBILE_SCREEN_SIZE}px) {
        left: ${(props) => {
            for (let i = 0; i < props.numberOfPhotos; i++) {
                if (i < 2) {
                    if (props.lastActivePhoto - props.position === i) {
                        return `${100 * -i}%`;
                    }
                } else if (props.hiddenPosition) {
                    return `-100%`;
                } else {
                    return '105%';
                }
            }
        }};
    }
`;

export const ProductText = styled.p<SliderType>`
    margin: 0;
    font-weight: 700;
    font-size: ${(props) => (props.bigSlider ? '14px' : '11px !important')};
    ${(props) =>
        props.bigSlider &&
        `
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 400; 
    `}
`;

export const BackIcon = styled(StyledArrowBack)<SliderType>`
    font-size: ${(props) => (props.bigSlider ? '1.4em' : '0.9em')};
    margin-right: 0.125rem;
    fill: white;
`;

export const ForwardIcon = styled(StyledArrowForward)<SliderType>`
    font-size: ${(props) => (props.bigSlider ? '1.4em' : '0.9em')};
    margin-left: 0.125rem;
    fill: white;
`;

export const IconWrapper = styled.div<SliderType>`
    width: ${(props) => (props.bigSlider ? '36px' : '24px')};
    height: ${(props) => (props.bigSlider ? '36px' : '24px')};
    position: absolute;
    background-color: black;
    opacity: 0.2;
    top: ${(props) => (props.bigSlider ? '45%' : '20%')};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
    z-index: 3;

    &:hover {
        opacity: 0.3;
    }
`;

export const BackIconWrapper = styled(IconWrapper)`
    left: 0;
`;

export const ForwardIconWrapper = styled(IconWrapper)`
    right: 0;
`;
