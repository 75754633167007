import styled from 'styled-components';
import { greyBorder, greyLight, black, mailColor } from '../../../styles/Variables';
import { SiGmail, SiYahoo, SiMicrosoftoutlook } from 'react-icons/si';
import { BsEnvelopeFill } from 'react-icons/bs';
import placeholder from '../../../static/images/placeholder.jpg';

export const MailModal = styled.div`
    width: 500px;
    min-height: 300px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: ${greyLight};
    color: ${black};
    border-radius: 1em;
    border: 1px solid ${greyBorder};
    padding: 0.3em;
    z-index: 3;
`;

export const MailHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const MailClose = styled.span`
    cursor: pointer;
`;

export const MailHeaderText = styled.span`
    font-size: 1.2em;
    font-weight: 600;
`;

export const MailBodyIcons = styled.div`
    width: 96%;
    background-color: white;
    margin: 2px auto;
    padding: 2%;
    display: flex;
    justify-content: center;
`;

export const GmailWrapper = styled.div`
    width: 100px;
    height: 100px;
    background-color: #a83225;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const GmailIcon = styled(SiGmail)`
    font-size: 1.6em;
    fill: white;
    padding: 0.5em;
`;

export const YahooWrapper = styled(GmailWrapper)`
    background-color: #6a1988;
`;

export const YahooIcon = styled(SiYahoo)`
    font-size: 1.6em;
    fill: white;
    padding: 0.5em;
`;

export const OutlookWrapper = styled(GmailWrapper)`
    background-color: #0074c6;
`;

export const OutloouIcon = styled(SiMicrosoftoutlook)`
    font-size: 1.6em;
    fill: white;
    padding: 0.5em;
`;

export const CommonMailWrapper = styled(GmailWrapper)`
    background-color: ${mailColor};
`;

export const CommonMailIcon = styled(BsEnvelopeFill)`
    font-size: 1.6em;
    fill: white;
    padding: 0.5em;
`;

export const SocialMediaLink = styled.a`
    text-decoration: none;
    margin: 2em 0;
`;

export const MailBodyContent = styled.div`
    width: 96%;
    background-color: white;
    margin: 0 auto;
    margin-bottom: 4px;
    padding: 2%;
    display: flex;
`;

export const MailBodyContentImg = styled.div`
    width: 100px;
    height: 100px;
    background-image: url(${placeholder});
    background-size: contain;
    background-position: center;
    margin-right: 1em;
`;

export const MailBodyTitle = styled.p`
    font-weight: 600;
`;
