import React from 'react';
import {
    MailModal,
    MailHeader,
    MailClose,
    MailHeaderText,
    MailBodyIcons,
    GmailWrapper,
    GmailIcon,
    YahooWrapper,
    YahooIcon,
    OutlookWrapper,
    OutloouIcon,
    CommonMailWrapper,
    CommonMailIcon,
    SocialMediaLink,
    MailBodyContent,
    MailBodyContentImg,
    MailBodyTitle,
} from './ModalShareStyle';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ModalShare = ({ title, setIsMailModal }) => {
    const siteUrl = location.href ? location.href : '';
    const { t } = useTranslation();

    return (
        <MailModal>
            <MailHeader>
                <MailHeaderText>{t('selectYourEmailService')}</MailHeaderText>
                <MailClose onClick={() => setIsMailModal(false)}>{t('close')}</MailClose>
            </MailHeader>
            <MailBodyIcons>
                <SocialMediaLink
                    href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${title}&body=${siteUrl}&ui=2&tf=1&pli=1`}
                    target="_blank"
                >
                    <GmailWrapper>
                        <GmailIcon />
                        Gmail
                    </GmailWrapper>
                </SocialMediaLink>
                <SocialMediaLink href={`https://compose.mail.yahoo.com/?To=mailto:?body=${siteUrl}`} target="_blank">
                    <YahooWrapper>
                        <YahooIcon />
                        Yahoo!
                    </YahooWrapper>
                </SocialMediaLink>
                <SocialMediaLink href={`mailto:?subject=&${title}body=${siteUrl}`} target="_blank">
                    <OutlookWrapper>
                        <OutloouIcon />
                        Outlook
                    </OutlookWrapper>
                </SocialMediaLink>
                <SocialMediaLink href={`mailto:?subject=&${title}body=${siteUrl}`} target="_blank">
                    <CommonMailWrapper>
                        <CommonMailIcon />
                        Email
                    </CommonMailWrapper>
                </SocialMediaLink>
            </MailBodyIcons>
            <MailBodyContent>
                <MailBodyContentImg />
                <div>
                    <MailBodyTitle>{title}</MailBodyTitle>
                    <a href={siteUrl}>{siteUrl}</a>
                </div>
            </MailBodyContent>
        </MailModal>
    );
};

export default ModalShare;
