import styled from 'styled-components';
import { white, darkGreen, greyBorderButton, blueTable } from '../../../styles/Variables';
import { ContainerType } from '../interfaces';

export const Container = styled.div<ContainerType>`
    padding: ${(props) => (props.isActive ? '2em 0' : 0)};
    transition: max-height 0.3s;
    max-height: ${(props) => (props.isActive ? '3000px' : '0')};
    overflow: hidden;
`;

export const NavigationButton = styled.button`
    background-color: ${white};
    font-size: 0.875em;
    font-family: Arial, Helvetica;
    color: ${darkGreen};
    border-radius: 0px;
    border: 1px solid ${greyBorderButton};
    padding: 0.4em 1.2em;
    text-align: center;
    cursor: pointer;
    margin: 0 10px 15px 0px;
`;

export const Details = styled.div`
    overflow-x: auto;
    border: none;
`;

export const Table = styled.table`
    width: 100%;
    border-spacing: 0;
`;

export const TableHeaderItem = styled.th`
    background-color: ${blueTable};
    border-right: 1px solid ${white};
    font-size: 0.875rem;
    font-family: Arial, Helvetica;
    font-weight: 700;
    padding: 0.43em 0.625em 0.2em 1.25em;
    text-align: left;
    vertical-align: top;
`;

export const TableHeaderItemUnits = styled(TableHeaderItem)``;

export const TableHeaderUnits = styled.div``;

interface TableSubHeaderItemType {
    readonly addRightBorder: boolean;
}

export const TableSubHeaderItem = styled.th<TableSubHeaderItemType>`
    background-color: ${blueTable};
    border-right: ${(props) => (props.addRightBorder ? `1px solid ${white}` : 'unset')};
    font-weight: 400;
    font-size: 0.875em;
    font-family: Arial, Helvetica;
    text-align: left;
    padding: 1em 0.625em 1em 1.25em;
`;

export const TableRow = styled.tr`
    &:nth-child(odd) {
        background-color: white;
    }
`;
interface TableItemType {
    readonly addRightBorder: boolean;
}

export const TableItem = styled.td<TableItemType>`
    border-right: ${(props) => (props.addRightBorder ? `1px solid ${white}` : 'unset')};
    font-size: 0.875em;
    font-family: Arial, Helvetica;
    text-align: left;
    padding: 0.6em 0.625em 0.6em 1.25em;
`;

export const TechnicalDataFootnoteText = styled.p`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 27px;
    padding-left: 1.25rem;
    word-break: break-word;

    @media (max-width: 767px) {
        padding-left: 0;
    }
`;

export const ImageWrapper = styled.div`
    width: fit-content;
    height: 100%;
`;

export const Image = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-height: 1000px;
`;
