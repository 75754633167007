import styled from 'styled-components';
import {
    blue,
    white,
    darkGreen,
    greyLight,
    greyBorderButton,
    lightGray,
    twitterColor,
    facebookColor,
    mailColor,
    containerWidth,
} from '../../styles/Variables';
import { deviceMax } from '../../styles/MediaQuery';
import selectDivider from '../../static/images/landingPage/divider.png';
import selectArrow from '../../static/images/landingPage/arrow_dropdown.png';
import { FaFacebookSquare, FaTwitterSquare, FaEnvelopeSquare } from 'react-icons/fa';

export const Title = styled.h1`
    color: ${blue};
    font-size: 2.5em;
    font-weight: 400;
    letter-spacing: 0.025em;
    font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, sans-serif;
`;

export const SubTitle = styled.h3`
    letter-spacing: 0.025em;
    margin-bottom: 0.375em;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 400;
    font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, Arial;
`;

export const HeaderProduct = styled.header`
    width: ${containerWidth};
    margin: auto;
    @media ${deviceMax.laptop} {
        width: unset;
    }
`;

export const NavigationList = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    margin-bottom: 0;
    @media ${deviceMax.laptop} {
        display: none;
    }
`;

interface NavigationButtonInterface {
    readonly isActive: boolean;
}

export const NavigationButton = styled.button<NavigationButtonInterface>`
    font-family: Arial, Helvetica, sans-serif;
    background-color: ${(props) => (props.isActive ? greyLight : white)};
    font-size: 14px;
    color: ${darkGreen};
    border-radius: 0px;
    border: 1px solid ${greyBorderButton};
    border-bottom: ${(props) => (props.isActive ? `1px solid ${greyLight}` : `1px solid ${greyBorderButton}`)};
    padding: 1em 2em;
    text-align: center;
    cursor: pointer;
`;

export const ProductComponent = styled.div`
    background-color: ${greyLight};
`;

export const ProductComponentWrapper = styled.div`
    width: ${containerWidth};
    margin: auto;
    @media ${deviceMax.laptop} {
        width: unset;
    }
`;

export const ProductWrapper = styled.div`
    @media ${deviceMax.laptop} {
        padding: 0 1em;
    }
`;

export const SelectWrapper = styled.div`
    margin: 1.5rem 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media ${deviceMax.laptop} {
        display: flex;
    }
`;

export const Select = styled.select`
    background: url(${selectDivider}) no-repeat 84% 43%, url(${selectArrow}) no-repeat 93% 45%,
        linear-gradient(180deg, #fafbfc 30%, #eff2f5 90%);
    border: 1px solid ${lightGray};
    cursor: pointer;
    padding-left: 0.8rem;
    width: 17rem;
    height: 2.5rem;
    color: ${darkGreen};
    border-radius: 5px;
    appearance: none;
    outline: none;

    option {
        color: ${darkGreen};
    }
`;

export const SocialMedia = styled.div`
    width: ${containerWidth};
    margin: 3em auto;
    @media ${deviceMax.laptop} {
        width: unset;
    }
`;

export const FacebookIcon = styled(FaFacebookSquare)`
    fill: ${facebookColor};
    font-size: 2em;
    cursor: pointer;
    transition: opacity 0.4s;
    padding: 0 0.1em;
    &:hover {
        opacity: 0.8;
    }
`;

export const TwitterIcon = styled(FaTwitterSquare)`
    fill: ${twitterColor};
    font-size: 2em;
    cursor: pointer;
    transition: opacity 0.4s;
    padding: 0 0.1em;
    &:hover {
        opacity: 0.8;
    }

    @media ${deviceMax.laptop} {
        padding: 0 0.1em 0 0;
    }
`;

export const MailIcon = styled(FaEnvelopeSquare)`
    fill: ${mailColor};
    font-size: 2em;
    cursor: pointer;
    transition: opacity 0.4s;
    padding: 0 0.1em;
    &:hover {
        opacity: 0.8;
    }
`;
