import styled from 'styled-components';
import { white, greenProduct, greyBorder, greenLightest } from '../../../styles/Variables';
import { deviceMax, deviceMin } from '../../../styles/MediaQuery';
import { ContainerType } from '../interfaces';

export const Container = styled.div<ContainerType>`
    padding: ${(props) => (props.isActive ? '2em 0 2em 40px' : 0)};
    transition: max-height 0.3s;
    max-height: ${(props) => (props.isActive ? '3000px' : '0')};
    overflow: hidden;
`;

export const ProductList = styled.ul`
    list-style: none;
    padding: 0.3em 0em 0.3em 2em;
    width: calc(22% - 35px);

    &:not(:last-child) {
        border-right: 1px solid ${white};
    }
`;

export const ListWrapper = styled.div`
    display: flex;
    margin-left: 3em;

    @media ${deviceMax.laptop} {
        display: none;
    }
`;
export const ListWrapperMobile = styled.div`
    display: flex;
    margin-left: 3em;

    @media ${deviceMin.laptop} {
        display: none;
    }

    ${ProductList} {
        width: 45%;
    }
`;

export const ProductListOneLine = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
`;

export const ProductListOneLineItem = styled.li`
    width: calc(22% - 35px);
    padding: 0.8em 0 0.8em 2em;
    cursor: pointer;
    color: ${greenProduct};
    font-family: Arial, Helvetica;

    &:not(:last-child) {
        border-right: 1px solid ${white};
    }

    @media ${deviceMax.laptop} {
        width: 40%;
    }
`;

export const ProductListItem = styled.li`
    cursor: pointer;
    color: ${greenProduct};
    padding: 0.5em 0;
    font-family: Arial, Helvetica;
`;

export const NumberWrapper = styled.div`
    width: 46px;
    min-width: 46px;
    height: 46px;
    background-color: ${greenProduct};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: ${white};
    font-size: 1.25em;
    font-weight: 600;
    font-family: Arial, Helvetica;
    margin-right: 1em;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
`;

export const TitleText = styled.p`
    font-weight: 600;
    font-family: Arial, Helvetica;
    padding: 0;
`;

export const Types = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-block: 1.5em;
`;

export const TypesList = styled.ul`
    list-style: none;
    margin: 0 1.5em 0 1.5em;
`;

export const TypeListItem = styled.li`
    display: flex;
    align-items: center;
    font-family: Arial, Helvetica;
`;

interface CheckBoxType {
    readonly status: string;
}

export const CheckBox = styled.div<CheckBoxType>`
    width: 1.5em;
    height: 1.5em;
    background-color: ${white};
    position: relative;
    cursor: pointer;
    border: 1px solid ${greyBorder};
    margin: 0.5em 0.8em;

    &::before {
        content: '';
        position: absolute;
        width: 0.75em;
        height: 0.75em;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        background-color: ${(props) => {
            if (props.status === 'active') {
                return greenProduct;
            } else if (props.status === 'hover') {
                return greenLightest;
            } else {
                return white;
            }
        }};
    }
`;

interface TypesWrapperType {
    readonly isActive: boolean;
}
export const TypesWrapper = styled.div<TypesWrapperType>`
    height: ${(props) => (props.isActive ? 'unset' : '0px')};
    visibility: ${(props) => (props.isActive ? 'unset' : 'hidden')};
`;

export const PlanningDataLink = styled.a`
    display: inline-block;
    margin-left: 4.8em;
    border: 1px solid #ded7d7;
    background-color: #f2f4f7;
    padding: 15px;
    color: ${greenProduct};
    text-decoration: none;
`;
