import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import { lightGray } from '../../styles/Variables';
import { deviceMax } from '../../styles/MediaQuery';

export const Container = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(214, 214, 214, 0.9);
    z-index: 4;
`;

export const YoutubeWrapper = styled.div`
    width: 700px;
    max-width: 100%;
    background-color: white;
    padding-bottom: 0.5rem;
    @media ${deviceMax.tablet} {
        width: 100%;
    }
`;

export const CloseIconWrapper = styled.div`
    width: 100%;
    background-color: white;
    text-align: right;
`;

export const CloseIcon = styled(AiOutlineClose)`
    fill: ${lightGray};
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 0.5rem;
`;
