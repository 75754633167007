import React, { ReactNode } from 'react';
import {
    Container,
    ProductInformation,
    Download,
    DownloadIcon,
    ProductHeader,
    ProductParagraph,
} from './ProductDownloadsStyle';
import { formatBytes } from '../../../utils/formatBytes';

const ProductDownloads = ({ downloads: { field_proudct_downloads }, isActive }) => {
    const displayProductsToDownload = (): Function =>
        field_proudct_downloads?.map((product: any, index: number) => displayProductToDownload(product, index));

    const displayFileType = (filemime: string): string => filemime.replace('application/', '').toUpperCase();

    const displayProductToDownload = (product: any, index: number): ReactNode => (
        <ProductInformation key={product?.field_media_document ?? index}>
            <div>
                <ProductHeader>{product?.description}</ProductHeader>
                <ProductParagraph>
                    {displayFileType(product?.details?.filemime ?? 'PDF')}, {formatBytes(product?.details?.weight)}
                </ProductParagraph>
            </div>
            <a href={product?.field_media_document?.length > 0 ? product.field_media_document : '#'} target="_blank">
                <Download>
                    <DownloadIcon />
                </Download>
            </a>
        </ProductInformation>
    );

    return <Container isActive={isActive}>{displayProductsToDownload()}</Container>;
};

export default ProductDownloads;
